<template>
  <b-container
    class="mb-5"
    fluid
  >
    <div class="d-flex justify-content-end mb-3 pb-0">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-card>
      <b-row class="mb-2">

        <b-col
          cols="12"
          sm="4"
        >
          <div class="form-group">
            <label>
              <strong>Company</strong>
            </label>
            <b-select
              v-model="tableHistories.filter.company"
              :options="filterCompanies"
            />
          </div>
        </b-col>

        <b-col
          cols="12"
          sm="4"
        >
          <div class="form-group">
            <label>
              <strong>Organization</strong>
            </label>
            <b-select
              v-model="tableHistories.filter.organization"
              :options="filterOrganizations"
            />
          </div>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <label>
              <strong>Funding Type</strong>
            </label>
            <b-select
              v-model="tableHistories.filter.funding_type"
              :options="fundingTypes"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <label for="date_from">
              <strong>Date From</strong>
            </label>
            <b-input
              id="date_from"
              v-model="tableHistories.filter.date_from"
              type="date"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <label for="date_from">
              <strong>Date To</strong>
            </label>
            <b-input
              id="date_from"
              v-model="tableHistories.filter.date_to"
              type="date"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <label>
              <strong>Status</strong>
            </label>
            <b-select
              v-model="tableHistories.filter.status"
              :options="list.statuses"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group>
            <label>
              <strong>Search</strong>
            </label>
            <b-input
              v-model="tableHistories.filter.search"
              type="text"
              placeholder="search here"
              debounce="1000"
            />
          </b-form-group>
        </b-col>

      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="tableHistories.perPage"
        :current-page="tableHistories.currentPage"
        :items="tableProvider"
        :fields="tableHistories.fields"
        :sort-by.sync="tableHistories.sortBy"
        :sort-desc.sync="tableHistories.sortDesc"
        :sort-direction="tableHistories.sortDirection"
        :filter="tableHistories.filter"
        :filter-included-fields="tableHistories.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ tableHistories.currentPage * tableHistories.perPage - tableHistories.perPage + (data.index + 1) }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              @click="onView(row.item)"
            >
              View
            </b-button>
          </div>
        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableHistories.perPage"
              :options="tableHistories.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableHistories.currentPage"
            :total-rows="tableHistories.totalRows"
            :per-page="tableHistories.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <!-- Modal View Form -->
    <b-modal
      id="modal-view-form"
      size="xl"
      scrollable
      no-close-on-backdrop
      no-enforce-focus
      title="View Detail"
    >
      <b-card no-body>
        <b-row>

          <!-- Requester -->
          <b-col
            cols="12"
          >
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group>
                  <label for="created_at">
                    <strong>Requested At</strong>
                  </label>
                  <b-input
                    id="created_at"
                    v-model="maintenanceHistory.created_at"
                    type="text"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group>
                  <label for="requestor_name">
                    <strong>Requested By</strong>
                  </label>
                  <b-input
                    id="requestor_name"
                    v-model="maintenanceHistory.requestor_name"
                    disabled
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>

          <!-- Company & Organizations -->
          <b-col
            cols="12"
          >
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group>
                  <label for="company_name">
                    <strong>Company</strong>
                  </label>
                  <b-input
                    id="company_name"
                    v-model="maintenanceHistory.company_name"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group>
                  <label for="organization_name">
                    <strong>Organization</strong>
                  </label>
                  <b-input
                    id="organization_name"
                    v-model="maintenanceHistory.organization_name"
                    disabled
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>

          <!-- Fund Type & Amounts -->
          <b-col
            cols="12"
          >
            <b-row>
              <b-col
                cols="12"
                md="3"
              >
                <b-form-group>
                  <label for="amount">
                    <strong>Estimated Amount</strong>
                  </label>
                  <b-input
                    id="amount"
                    v-model="maintenanceHistory.estimated_amount"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <b-form-group>
                  <label for="actual_amount">
                    <strong>Actual Amount</strong>
                  </label>
                  <b-input
                    id="actual_amount"
                    v-model="maintenanceHistory.actual_amount"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group>
                  <label for="funding_type">
                    <strong>Funding Type</strong>
                  </label>
                  <b-input
                    id="funding_type"
                    v-model="fundingTypeValue"
                    disabled
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>

          <!-- Vehicle -->
          <b-col
            cols="12"
          >
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group>
                  <label for="vehicle_license_plate">
                    <strong>Vehicle Plate Number</strong>
                  </label>
                  <b-input
                    id="vehicle_license_plate"
                    v-model="maintenanceHistory.vehicle_license_plate"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group>
                  <label for="vehicle_name">
                    <strong>Vehicle Name</strong>
                  </label>
                  <b-input
                    id="vehicle_name"
                    v-model="maintenanceHistory.vehicle_name"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group>
                  <label for="vehicle_category_name">
                    <strong>Vehicle Category</strong>
                  </label>
                  <b-input
                    id="vehicle_category_name"
                    v-model="maintenanceHistory.vehicle_category_name"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group>
                  <label for="vehicle_type">
                    <strong>Vehicle Type</strong>
                  </label>
                  <b-input
                    id="vehicle_type"
                    v-model="vehicleIsValue"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group>
                  <label for="noted_by_at">
                    <strong>Last Odometer</strong>
                  </label>
                  <b-input
                    id="noted_by_at"
                    v-model="maintenanceHistory.last_odometer"
                    type="text"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group>
                  <label for="current_odometer">
                    <strong>Current Odometer</strong>
                  </label>
                  <b-input
                    id="current_odometer"
                    v-model="maintenanceHistory.current_odometer"
                    type="text"
                    disabled
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>

          <!-- Damage Items and Reasons-->
          <b-col
            cols="12"
          >
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group>
                  <label for="damaged_item_specifications">
                    <strong>Ano ang Nasira?</strong>
                  </label>
                  <b-textarea
                    id="damaged_item_specifications"
                    v-model="maintenanceHistory.damaged_item_specifications"
                    rows="6"
                    max-rows="8"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group>
                  <label for="cause_of_damage">
                    <strong>Rason ng Pagkasira?</strong>
                  </label>
                  <b-textarea
                    id="cause_of_damage"
                    v-model="maintenanceHistory.cause_of_damage"
                    rows="6"
                    max-rows="8"
                    disabled
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>

          <!-- Attachments -->
          <b-col
            v-if="maintenanceHistory.attachments.length > 0"
            cols="12"
          >
            <label><strong>Attachments</strong></label>
            <b-table
              class="mt-1"
              :fields="attachmentTableSettings.fields"
              :items="maintenanceHistory.attachments"
              responsive
              striped
              hover
            >
              <template #cell(actions)="row">
                <div class="text-nowrap">
                  <b-button
                    size="sm"
                    class="mr-1"
                    @click="onPreviewAttachment(row.item)"
                  >
                    Preview
                  </b-button>
                </div>
              </template>
            </b-table>
          </b-col>

          <!-- Signatories -->
          <b-col
            cols="12"
          >
            <b-row>
              <!-- Noted By -->
              <b-col
                v-if="maintenanceHistory.approver_level >= 1"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group>
                      <label for="noted_by_at">
                        <strong>{{ notedByStatus }} At</strong>
                      </label>
                      <b-input
                        id="noted_by_at"
                        v-model="maintenanceHistory.noted_by_at"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group>
                      <label for="noted_by_name">
                        <strong>{{ notedByStatus }} By</strong>
                      </label>
                      <b-input
                        id="noted_by_name"
                        v-model="maintenanceHistory.noted_by_name"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <b-form-group>
                      <label for="noted_by_remarks">
                        <strong>{{ notedByStatus }} By Remarks</strong>
                      </label>
                      <b-textarea
                        id="noted_by_remarks"
                        v-model="maintenanceHistory.noted_by_remarks"
                        rows="6"
                        max-rows="8"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="maintenanceHistory.maintenance_items.length > 0"
                    cols="12"
                  >
                    <label><strong>Parte o Materyales na Kelangan</strong></label>
                    <b-table
                      class="mt-1"
                      :fields="partsNeededTableSettings.fields"
                      :items="maintenanceHistory.maintenance_items"
                      responsive
                      striped
                      hover
                    >
                      <template #cell()="data">
                        <div class="text-nowrap">
                          {{ data.value }}
                        </div>
                      </template>
                    </b-table>
                  </b-col>
                </b-row>
              </b-col>
              <!-- Approved By -->
              <b-col
                v-if="notedByStatus === 'Noted' && maintenanceHistory.approver_level >= 2"
                cols="12"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group>
                      <label for="approved_by_at">
                        <strong>{{ approvedByStatus }} At</strong>
                      </label>
                      <b-input
                        id="approved_by_at"
                        v-model="maintenanceHistory.approved_by_at"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group>
                      <label for="approved_by_name">
                        <strong>{{ approvedByStatus }} By</strong>
                      </label>
                      <b-input
                        id="approved_by_name"
                        v-model="maintenanceHistory.approved_by_name"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <b-form-group>
                      <label for="approved_by_remarks">
                        <strong>{{ approvedByStatus }} By Remarks</strong>
                      </label>
                      <b-textarea
                        id="approved_by_remarks"
                        v-model="maintenanceHistory.approved_by_remarks"
                        rows="6"
                        max-rows="8"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <!-- Purchased By -->
              <b-col
                v-if="maintenanceHistory.funding_type === 'cash-advance' && notedByStatus === 'Noted' && approvedByStatus === 'Approved' && maintenanceHistory.approver_level >= 3"
                cols="12"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group>
                      <label for="purchased_by_at">
                        <strong>{{ purchasedByStatus }} At</strong>
                      </label>
                      <b-input
                        id="purchased_by_at"
                        v-model="maintenanceHistory.purchased_by_at"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group>
                      <label for="purchased_by_name">
                        <strong>{{ purchasedByStatus }} By</strong>
                      </label>
                      <b-input
                        id="purchased_by_name"
                        v-model="maintenanceHistory.purchased_by_name"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <b-form-group>
                      <label for="purchased_by_remarks">
                        <strong>{{ purchasedByStatus }} By Remarks</strong>
                      </label>
                      <b-textarea
                        id="purchased_by_remarks"
                        v-model="maintenanceHistory.purchased_by_remarks"
                        rows="6"
                        max-rows="8"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <!-- Completed By -->
              <b-col
                v-if="maintenanceHistory.status === 'Completed'"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group>
                      <label for="completed_by_at">
                        <strong>Completed At</strong>
                      </label>
                      <b-input
                        id="completed_by_at"
                        v-model="maintenanceHistory.completed_by_at"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group>
                      <label for="completed_by_name">
                        <strong>Completed By</strong>
                      </label>
                      <b-input
                        id="completed_by_name"
                        v-model="maintenanceHistory.completed_by_name"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>

      <template #modal-footer="{ cancel }">
        <b-overlay
          :show="state.busy"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
        >
          <b-button
            v-if="maintenanceHistory.approved_by_status === 'Approved'"
            @click="onGenerateRepairPrintingForm()"
          >
            Print
          </b-button>
        </b-overlay>

        <b-button
          variant="outline-dark"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>

    </b-modal>

    <!-- modal preview image -->
    <b-modal
      id="modal-preview-image"
      size="lg"
      centered
      scrollable
      no-close-on-backdrop
      no-enforce-focus
      aria-hidden="false"
      :title="`Preview Attachment [${file.name}]`"
    >
      <div class="d-flex justify-content-center">
        <b-img
          fluid
          :src="file.preview"
        />
      </div>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="outline-dark"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-preview-print"
      centered
      size="xl"
      scrollable
      no-close-on-backdrop
      no-enforce-focus
      aria-hidden="false"
    >
      <div style="height: 550px;">
        <iframe
          style="height: 100%; width: 100%"
          :src="pdfData"
        />
      </div>

      <div id="table-attachments">
        <table class="col-12 mt-5">
          <thead>
            <tr>
              <th class="h2">
                <div class="mb-5">
                  Attachments:
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="attachment in maintenanceHistory.attachments"
              :key="attachment.file_hash_name"
            >
              <td
                class="p-0 m-0"
              >
                <b-row class="mb-5">
                  <b-col
                    class="text-center"
                    cols="12"
                  >
                    <b-img
                      fluid
                      :src="attachment.url"
                      style="height: calc(100vh / 2.7); margin-bottom: 50px;"
                    />
                  </b-col>
                  <b-col
                    class="h3 text-center"
                    cols="12"
                  >
                    {{ attachment.file_original_name }}
                  </b-col>
                </b-row>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          v-print="'#table-attachments'"
        >
          Print Attachments
        </b-button>
        <b-button
          variant="outline-dark"
          @click="cancel()"
        >
          Close Preview
        </b-button>
      </template>
    </b-modal>

  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { SSMMaintenance, SSharedList } from '@/services'
import formatter from '@/mixins/formatter'
import url from '@/mixins/url'
import helper from '@/mixins/helper'
import print from 'vue-print-nb'

export default {
  name: 'VSMHistories',

  middleware: ['auth', 'senior-mechanic'],

  metaInfo () {
    return {
      title: 'Approval Histories'
    }
  },

  directives: {
    print
  },

  mixins: [formatter, url, helper],

  data () {
    return {
      state: {
        busy: false,
        file: {
          previewing: false
        }
      },
      file: {
        name: '',
        preview: null
      },
      list: {
        companies: [],
        organizations: [],
        statuses: [
          { text: 'All', value: 'All' },
          { text: 'Approved', value: 'Approved' },
          { text: 'Disapproved', value: 'Disapproved' }
        ]
      },
      maintenanceHistory: {
        id: 0,
        document_number: '',
        actual_amount: 0,
        estimated_amount: 0,
        funding_type: 'petty-cash',
        company_name: '',
        organization_name: '',
        position_name: 'Requester',
        requestor_name: '',
        user_name: '',
        vehicle_name: '',
        vehicle_category_name: '',
        vehicle_license_plate: '',
        vehicle_type: 'owned',
        damaged_item_specifications: '',
        cause_of_damage: '',
        status: 'Processing',
        attachments: [],
        last_odometer: 0,
        current_odometer: 0,
        noted_by_name: '',
        noted_by_position: 'Chief Mechanic',
        noted_by_remarks: '',
        noted_by_at: '',
        noted_by_status: 'Pending',
        maintenance_items: [],
        approved_by_name: '',
        approved_by_position: 'Logistics Manager',
        approved_by_remarks: '',
        approved_by_at: '',
        approved_by_status: 'Pending',
        purchased_by_name: '',
        purchased_by_remarks: '',
        purchased_by_at: '',
        purchased_by_status: 'Pending',
        completed_by_name: '',
        completed_by_at: '',
        approver_level: 0,
        created_at: '',
        updated_at: ''
      },
      tableHistories: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: {
          company: 'All',
          organization: 'All',
          funding_type: 'All',
          status: this.$route.params?.noted_by_status || 'All',
          date_from: '',
          date_to: ''
        },
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, label: 'requested at', sortable: true },
          { key: 'document_number' },
          { key: 'requestor_name', label: 'Requested By' },
          { key: 'vehicle_license_plate', label: 'License Plate' },
          { key: 'vehicle_category_name', label: 'Vehicle Category' },
          'status',
          { key: 'updated_at', formatter: this.dateTimeFormatter, sortable: true },
          'noted_by_status',
          { key: 'noted_by_at', formatter: this.dateTimeFormatter, sortable: true }
        ]
      },
      attachmentTableSettings: {
        fields: [
          'actions',
          { key: 'file_original_name', label: 'filename' }
        ]
      },
      partsNeededTableSettings: {
        fields: [
          { key: 'name' },
          { key: 'quantity' },
          { key: 'unit_of_measurement', label: 'UoM' },
          { key: 'amount' },
          { key: 'funding_type', formatter: this.findFundingType },
          { key: 'supplier_name' },
          { key: 'item_from', formatter: this.findPartsFrom },
          { key: 'item_status', formatter: this.findItemStatus },
          { key: 'last_request', formatter: this.checkLastRequest }
        ]
      },
      pdfData: ''
    }
  },

  computed: {

    filterCompanies () {
      return [{ value: 'All', text: 'All' }].concat(this.list.companies)
    },

    filterOrganizations () {
      const organizations = this.list.organizations.filter(
        organization => {
          const company = this.tableHistories.filter.company
          if (company !== 'All') {
            return organization.company === company
          }
          return organization
        }
      )
      return [{ value: 'All', text: 'All' }].concat(organizations)
    },

    fundingTypeValue () {
      return this.findFundingType(this.maintenanceHistory.funding_type)
    },

    vehicleIsValue () {
      return this.findVehicleIs(this.maintenanceHistory.vehicle_type)
    },

    notedByStatus () {
      return ['Approved', 'Pending'].includes(this.maintenanceHistory.noted_by_status) ? 'Noted' : 'Disapproved'
    },

    approvedByStatus () {
      return ['Approved', 'Pending'].includes(this.maintenanceHistory.approved_by_status) ? 'Approved' : 'Disapproved'
    },

    purchasedByStatus () {
      return ['Approved', 'Pending'].includes(this.maintenanceHistory.purchased_by_status) ? 'Purchased' : 'Disapproved'
    }
  },

  watch: {
    'tableHistories.filter.company' () {
      this.tableHistories.filter.organization = 'All'
    }
  },

  mounted () {
    core.index()
    this.getOptions()
  },

  methods: {

    async onGenerateRepairPrintingForm () {
      this.state.busy = true

      const pdfDocument = this.createPDFOptions({
        info: {
          title: 'Repair Form',
          author: '',
          creator: '',
          subject: ''
        },
        requestedBy: {
          id: this.maintenanceHistory.id,
          document_number: this.maintenanceHistory.document_number,
          organization_name: this.maintenanceHistory.organization_name,
          position_name: this.maintenanceHistory.position_name,
          name: this.maintenanceHistory.requestor_name,
          date: this.dateLongFormatter(this.maintenanceHistory.created_at),
          time: this.dateTimeLongFormatter(this.maintenanceHistory.created_at),
          vehicle_license_plate: this.maintenanceHistory.vehicle_license_plate,
          damaged_item_specifications: this.maintenanceHistory.damaged_item_specifications,
          cause_of_damage: this.maintenanceHistory.cause_of_damage,
          maintenance_items: this.maintenanceHistory.maintenance_items
        },
        notedBy: {
          position_name: this.maintenanceHistory.noted_by_position,
          name: this.maintenanceHistory.noted_by_name,
          remarks: this.maintenanceHistory.noted_by_remarks,
          time: this.dateTimeLongFormatter(this.maintenanceHistory.noted_by_at),
          organization_name: ' ',
          last_odometer: this.maintenanceHistory.last_odometer,
          current_odometer: this.maintenanceHistory.current_odometer
        },
        approvedBy: {
          position_name: this.maintenanceHistory.approved_by_position,
          name: this.maintenanceHistory.approved_by_name,
          remarks: this.maintenanceHistory.approved_by_remarks,
          time: this.dateTimeLongFormatter(this.maintenanceHistory.approved_by_at),
          organization_name: ' '
        },
        attachments: this.maintenanceHistory.attachments
      })

      pdfDocument.getDataUrl(dataUrl => {
        this.pdfData = dataUrl
        this.state.busy = false
      })

      this.$bvModal.show('modal-preview-print')
    },

    async tableProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tableHistories.busy = true
      const filters = this.objectToUrl({
        page: currentPage,
        per_page: perPage,
        sort: sortBy,
        sort_desc: sortDesc,
        filter_text: filter.search,
        filter_company: filter.company,
        filter_organization: filter.organization,
        filter_from: filter.date_from,
        filter_to: filter.date_to,
        filter_status: filter.status,
        funding_type: filter.funding_type
      })

      return await SSMMaintenance.histories(filters).then(
        ({ data }) => {
          this.tableHistories.totalRows = data.total_rows
          return data.items.map(maintenance => {
            if (maintenance.attachments && maintenance.attachments.length > 0) {
              maintenance.attachments = maintenance.attachments.map(attachment => {
                attachment.url = this.getImageUrl(`${maintenance.attachments_path}/${attachment.file_hash_name}`)
                return attachment
              })
            }
            return maintenance
          })
        }
      )
        .catch(() => {
          this.tableHistories.totalRows = 0
          return []
        })
        .finally(() => {
          this.tableHistories.busy = false
        })
    },

    async getOptions () {
      await SSharedList.getCompanies().then(
        ({ data }) => {
          this.list.companies = data.map(
            ({ id, company_name }) => ({
              text: company_name,
              value: id
            })
          )
        }
      ).catch(() => {
        this.list.companies = []
      })

      await SSharedList.getOrganizations().then(
        ({ data }) => {
          this.list.organizations = data.map(
            ({ company_id, id, organization_name }) => ({
              company: company_id,
              text: organization_name,
              value: id
            })
          )
        }
      ).catch(() => {
        this.list.organizations = []
      })
    },

    onView (row) {
      this.maintenanceHistory.id = row.id
      this.maintenanceHistory.document_number = row.document_number

      this.maintenanceHistory.estimated_amount = row.estimated_amount
      this.maintenanceHistory.actual_amount = row.actual_amount
      this.maintenanceHistory.funding_type = row.funding_type

      this.maintenanceHistory.company_name = row.company_name
      this.maintenanceHistory.organization_name = row.organization_name
      this.maintenanceHistory.requestor_name = row.requestor_name
      this.maintenanceHistory.user_name = row.user_name

      this.maintenanceHistory.vehicle_name = row.vehicle_name
      this.maintenanceHistory.vehicle_category_name = row.vehicle_category_name
      this.maintenanceHistory.vehicle_license_plate = row.vehicle_license_plate
      this.maintenanceHistory.vehicle_type = row.vehicle_type

      this.maintenanceHistory.damaged_item_specifications = row.damaged_item_specifications
      this.maintenanceHistory.cause_of_damage = row.cause_of_damage

      this.maintenanceHistory.attachments_path = row.attachments_path
      this.maintenanceHistory.attachments = row.attachments

      this.maintenanceHistory.status = row.status

      this.maintenanceHistory.last_odometer = row.last_odometer
      this.maintenanceHistory.current_odometer = row.current_odometer

      this.maintenanceHistory.noted_by_name = row.noted_by_name
      this.maintenanceHistory.noted_by_position = row.noted_by_position
      this.maintenanceHistory.noted_by_remarks = row.noted_by_remarks
      this.maintenanceHistory.noted_by_at = row.noted_by_at
      this.maintenanceHistory.noted_by_status = row.noted_by_status
      this.maintenanceHistory.maintenance_items = row.maintenance_items

      this.maintenanceHistory.approved_by_name = row.approved_by_name
      this.maintenanceHistory.approved_by_position = row.approved_by_position
      this.maintenanceHistory.approved_by_remarks = row.approved_by_remarks
      this.maintenanceHistory.approved_by_at = row.approved_by_at
      this.maintenanceHistory.approved_by_status = row.approved_by_status

      if (row.funding_type === 'cash-advance') {
        this.maintenanceHistory.purchased_by_name = row.purchased_by_name
        this.maintenanceHistory.purchased_by_remarks = row.purchased_by_remarks
        this.maintenanceHistory.purchased_by_at = row.purchased_by_at
        this.maintenanceHistory.purchased_by_status = row.purchased_by_status
      }

      this.maintenanceHistory.approver_level = row.approver_level
      this.maintenanceHistory.completed_by_name = row.completed_by_name
      this.maintenanceHistory.completed_by_at = row.completed_by_at

      this.maintenanceHistory.created_at = this.dateTimeFormatter(row.created_at)
      this.maintenanceHistory.updated_at = this.dateTimeFormatter(row.updated_at)

      this.$bvModal.show('modal-view-form')
    },

    onPreviewAttachment (item) {
      this.file.name = item.file_original_name
      this.file.preview = item.url
      this.$bvModal.show('modal-preview-image')
    }
  }
}
</script>
